







































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import { IBranch } from "@/interfaces/IBranch";
import Datepicker from "@/components/Datepicker.vue";
import SelectableDaysOfWeek from "@/components/SelectableDaysOfWeek.vue";
import TimePicker from "@/components/TimePicker.vue";
import MultipleTime from "@/views/Theory/MultipleTime.vue";
import type { ITime } from "@/interfaces/ITime";
import { COURSE, LESSON, TheoryOptions } from "@/constants/TheoryOptions";
import { mixins } from "vue-class-component";
import TheoryMixin from "@/mixins/TheoryMixin";

@Component({
  components: {
    MultipleTime,
    TimePicker,
    SelectableDaysOfWeek,
    Datepicker,
    Validation,
    SaveButton,
    AbortButton,
  },
})
export default class TheoryCreateCard extends mixins(TheoryMixin) {
  public name = "TheoryCreateCard";

  protected theoryName = "";
  protected branch = null;
  protected theoryClass: any = null;
  protected slots = null;
  protected shownOnWebPage = null;
  protected startDate = null;
  protected endDate = null;
  protected daysOfWeek = [];
  protected theory: { id: number } | null = null;
  protected date = null;
  protected instructor = null;
  protected theoryLessonClass: any = null;

  protected get theoryOptions(): Array<any> {
    return TheoryOptions;
  }

  protected time = {
    startTime: "",
    endTime: "",
  };

  protected times: Array<ITime> = [];

  @Prop({ type: Array, default: () => [] })
  public branches!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  public theoryClasses!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  public instructors!: Array<any>;

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  protected onAbort(): void {
    this.$emit("on-abort");
  }

  protected onSubmit(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const data = {
      name: this.theoryName,
      branch: this.branch,
      slots: this.slots,
      shownOnWebPage: this.shownOnWebPage,
      instructor: this.instructor,
    };

    if (this.theory && this.theory.id === LESSON) {
      Object.assign(data, {
        date: this.date,
        duration: this.calcDuration(this.time.startTime, this.time.endTime),
        theoryClass: this.theoryLessonClass,
        time: this.time.startTime,
        furtherEducation: false,
      });
    } else {
      const timeFrame = [];
      timeFrame.push(this.time);

      this.times.forEach((time: ITime) => {
        if (time.endTime && time.startTime) {
          timeFrame.push(time);
        }
      });

      Object.assign(data, {
        startDate: this.startDate,
        endDate: this.endDate,
        timeFrame: timeFrame,
        daysOfWeek: this.daysOfWeek,
        theoryClasses: this.theoryClass,
      });
    }

    this.$emit("on-submit", data, this.theory);
  }

  @Validations()
  public validations(): Record<any, any> {
    return {
      theoryName: {
        required: requiredIf((val: any) => {
          return val.isTheoryCourse;
        }),
      },
      slots: { required, numeric },
      theory: { required },
      theoryClass: {
        required: requiredIf((val: any) => {
          return val.isTheoryCourse;
        }),
      },
      theoryLessonClass: {
        required: requiredIf((val: any) => {
          return !val.isTheoryCourse;
        }),
      },
      branch: { required },
      startDate: {
        required: requiredIf((val: any) => {
          return val.isTheoryCourse;
        }),
      },
      endDate: {
        required: requiredIf((val: any) => {
          return val.isTheoryCourse;
        }),
      },
      date: {
        required: requiredIf((val: any) => {
          return !val.isTheoryCourse;
        }),
      },
      time: {
        startTime: { required },
        endTime: { required },
      },
    };
  }

  public get branchOptions(): Array<any> {
    return this.branches.map((branch: IBranch) => {
      return {
        name: `${branch.postalCode} - ${branch.location}`,
        id: branch.id,
      };
    });
  }

  protected addMoreTime(): void {
    this.times.push({
      startTime: "",
      endTime: "",
    });
  }

  protected get isTheoryCourse(): boolean {
    return this.theory?.id === COURSE;
  }

  @Watch("isTheoryCourse")
  protected isTheoryCourseWatcher(isTheoryCourse: boolean): void {
    if (isTheoryCourse) {
      if (this.theoryLessonClass && !this.theoryClass) {
        this.theoryClass = this.theoryLessonClass;
      }
      // if (_.isObject(this.theoryClass) && this.theoryClass) {
      //   this.theoryClass = Array(this.theoryClass);
      // }
    } else {
      if (this.theoryClass && this.theoryClass.length > 0 && !this.theoryLessonClass) {
        this.theoryLessonClass = this.theoryClass[0];
      }
    }
  }
}
