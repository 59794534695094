


































































import { Component, Prop, Watch } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import { IBranch } from "@/interfaces/IBranch";
import { mixins } from "vue-class-component";
import TheoryMixin from "@/mixins/TheoryMixin";
import Datepicker from "@/components/Datepicker.vue";

@Component({
  components: {
    Validation,
    SaveButton,
    AbortButton,
    Datepicker,
  },
})
export default class TheoryEditCard extends mixins(TheoryMixin) {
  public name = "TheoryEditCard";

  @Prop({ type: String, default: () => "" })
  public currentTheoryName!: string;

  @Prop({ type: Number, default: () => 0 })
  public currentSlots!: number;

  @Prop({ type: Array, default: () => [] })
  public branches!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  public instructors!: Array<any>;

  @Prop()
  public currentBranch!: any;

  @Prop({ type: Number, default: () => 0 })
  public theoryId!: number;

  @Prop()
  public currentShowOnWebPage!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: any;

  @Prop()
  public currentStartDate!: any;

  @Prop()
  public currentEndDate!: any;

  protected theoryName = this.currentTheoryName;
  protected branch = this.branchName;
  protected theoryClass = null;
  protected slots = this.currentSlots;
  protected instructor = null;
  protected shownOnWebPage = this.currentShowOnWebPage;
  protected startDate = null;
  protected endDate = null;

  protected onAbort(): void {
    this.$emit("on-abort");
  }

  protected onSubmit(): void {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const data = {
      id: this.theoryId,
      name: this.theoryName,
      branch: this.branch,
      slots: this.slots,
      shownOnWebPage: this.shownOnWebPage,
      startDate: this.startDate,
      endDate: this.endDate,
      // instructor: this.instructor,
    };

    this.$emit("on-submit-edit", data, this.theoryId);
  }

  @Validations()
  public validations(): Record<any, any> {
    return {
      theoryName: { required },
      slots: { numeric },
      theoryId: { numeric },
      // instructor: { required },
      branch: { required },
    };
  }

  public get branchOptions(): Array<any> {
    return this.branches.map((branch: IBranch) => {
      return {
        name: `${branch.postalCode} - ${branch.location}`,
        id: branch.id,
      };
    });
  }

  public get branchName(): any {
    return {
      name: `${this.currentBranch.postalCode} - ${this.currentBranch.location}`,
      id: this.currentBranch.id,
    };
  }

  @Watch("currentStartDate", { immediate: true })
  public onCurrentStartDate(startDate: any) {
    if (startDate) {
      this.startDate = startDate;
    }
  }

  @Watch("currentEndDate", { immediate: true })
  public onCurrentEndDate(endDate: any) {
    if (endDate) {
      this.endDate = endDate;
    }
  }
}
