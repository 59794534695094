


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Avatar from "@/components/Avatars/Avatar.vue";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  components: { Table, Avatar },
})
export default class Students extends Vue {
  protected name = "Students";

  @Prop({ type: Array, default: () => [], required: true })
  public students!: any;

  @Prop({ type: Number, default: () => 0, required: true })
  public studentsTotalRows!: any;

  @Prop({ type: String })
  public filtered!: any;

  @Prop({ default: () => false, type: Boolean })
  public deletable!: any;

  @Prop({ default: () => true, type: Boolean })
  public hover!: any;

  @Prop({ default: () => false, type: Boolean })
  public hasNotPermissionWrite!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public clientSide!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public loading!: boolean;

  public studentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
  ];

  protected onRowDoubleClicked(item: any): void {
    this.$emit("row-dblclicked", item);
  }

  protected onRowClicked(item: any): void {
    this.$emit("row-clicked", item);
  }

  protected formatName(item: any): string {
    return formatStudentName(item.firstName, item.lastName);
  }

  protected formatEducations(item: any): string {
    return item.activeEducations?.join(" | ") || "";
  }

  protected onDelete(item: any, index: number): void {
    this.$emit("on-delete", item, index);
  }

  @Watch("filtered")
  public onSearch(term: string) {
    (this.$refs.fscTable as Table).onSearch(term);
  }
}
