











import { Component, Vue, Prop } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import { DATE_TIME_REGEX } from "@/constants/DateRegex";
@Component({
  components: { Validation, Datepicker },
})
export default class TheoryDateFilter extends Vue {
  public name = "TheoryDateFilter";

  @Prop()
  public fromDate!: string;

  @Prop()
  public toDate!: string;

  public internalFromDate: string = this.fromDate;
  public internalToDate: string = this.toDate;

  private fromDateInput(value: any) {
    this.internalFromDate = value;
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$emit("update:from-date", value);
    }
  }

  private toDateInput(value: any) {
    this.internalToDate = value;
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$emit("update:to-date", value);
    }
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      internalFromDate: {
        required,
        // checkDateRegex: (value:any) => DATE_TIME_REGEX.date.test(value),
      },
      internalToDate: {
        required,
        // checkDateRegex: (value: any) => DATE_TIME_REGEX.date.test(value)
      },
    };
  }
}
