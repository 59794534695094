import i18n from "@/i18n";

export const LESSON = 1;
export const COURSE = 2;

export const TheoryOptions = [
  {
    id: COURSE,
    name: i18n.tc("theory.fast_lane", 1),
    icon: "calendar-days",
  },
  {
    id: LESSON,
    name: i18n.tc("theory.single_lesson", 1),
    icon: "calendar-day",
  },
];
