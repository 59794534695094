









import { Component, Prop, Vue } from "vue-property-decorator";
import TheorySignature from "@/views/Theory/TheorySignature.vue";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: { FscModal, TheorySignature },
})
export default class TheorySignatureModal extends Vue {
  public name = "TheorySignatureModal";

  @Prop()
  public selectedTheoryLesson!: any;

  @Prop({ type: String, required: true })
  protected modalId!: string;
}
