








































































//@ts-ignore
import VueSignaturePad from "vue-signature-pad";
import { Component, Prop, Inject } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { namespace } from "vuex-class";
import { dataURLtoFile, getFile } from "@/utils/File";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { AxiosError } from "axios";
import TheoryMixin from "@/mixins/TheoryMixin";
import { mixins } from "vue-class-component";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";

const TheoryDocumentsModule = namespace("theory-documents");

@Component({
  components: { FscSimpleCard, AbortButton, SaveButton },
})
export default class TheorySignature extends mixins(TheoryMixin) {
  public name = "TheorySignature";

  @TheoryDocumentsModule.Action("uploadPngSignature")
  public uploadStudentPngSignature: any;

  @TheoryDocumentsModule.Getter("getPngSuccess")
  public signatureUploadSuccess: any;

  @TheoryDocumentsModule.Action("deleteSignature")
  public deleteSignature: any;

  @TheoryDocumentsModule.Getter("getSuccess")
  public signatureDeleteSuccess!: boolean;

  @TheoryDocumentsModule.Getter("getIsLoading")
  public signatureLoading!: boolean;

  @Inject("fetchTheoryLesson")
  public fetchTheoryLessonProvider!: () => Promise<void>;

  @Prop()
  public selectedTheoryLesson: any;

  public studentsTheoryLesson: any = [];

  public selectedStudentId: any;

  public selectedTheoryLessonId: any;

  public studentTheoryLessonId: any;

  public rowSelected = false;

  public signatureDirty = false;
  public hasSignature = false;
  public signatureFile = "";
  public fileSizeInvalid = false;

  public theoryFields = [
    {
      key: "studentName",
      label: "",
    },
    {
      key: "signed",
      label: "",
    },
  ];

  public mounted(): void {
    if (this.selectedTheoryLesson) {
      this.studentsTheoryLesson = this.selectedTheoryLesson.studentTheoryLessons;
    }
  }

  public async onPngSignatureUploadSubmit(file: any): Promise<void> {
    let formData = new FormData();
    formData.append("file", file);
    const options = {
      studentId: this.selectedStudentId,
      theoryLessonId: this.selectedTheoryLessonId,
      data: formData,
    };
    await this.uploadStudentPngSignature(options);
  }

  public async onRowSelected(row: any): Promise<void> {
    if (this.signatureDirty) {
      await this.onSignatureSignSubmit(false);
      await this.onSignatureClearPad();
    }

    if (row) {
      const selectedRow = row;
      this.hasSignature = selectedRow.signed;
      if (selectedRow.signed) {
        this.fetchSignature(selectedRow.id);
      }
      this.rowSelected = true;
      this.studentTheoryLessonId = selectedRow.id;
      this.selectedStudentId = selectedRow.studentId;
      this.selectedTheoryLessonId = this.selectedTheoryLesson.id;
    } else {
      this.rowSelected = false;
    }
  }

  public fetchSignature(id: number): void {
    getFile(
      {
        method: "GET",
        url: `/theory-lesson-documents/get-signature?studentTheoryLessonId=${id}`,
      },
      false
    )
      .then((blob: any) => {
        this.signatureFile = blob;
        this.hasSignature = !!blob;
        this.rowSelected = true;

        this.$nextTick(() => {
          if (!this.hasSignature && this.signatureDirty) {
            (this.$refs.signaturePad as VueSignaturePad).clearSignature();
          }
        });
      })
      .catch((error: AxiosError) => {
        //
      });
  }

  public async onSignatureClearPad(): Promise<void> {
    (this.$refs.signaturePad as VueSignaturePad).clearSignature();
    this.signatureDirty = false;
    this.fileSizeInvalid = false;
  }

  public async onDeleteSignature(): Promise<void> {
    this.fileSizeInvalid = false;
    await this.deleteSignature({
      studentTheoryLessonId: this.studentTheoryLessonId,
    });

    if (this.signatureDeleteSuccess) {
      this.studentsTheoryLesson.map((student: any) => {
        if (student.studentId === this.selectedStudentId) {
          return (student.signed = false);
        }
      });

      await this.fetchTheoryLessonProvider();
      this.hasSignature = false;
      this.rowSelected = true;
    }
  }

  public async onSignatureSignSubmit(fetchSignatureAfterSubmit: boolean): Promise<void> {
    if (this.hasNotPermissionWrite) return;

    const { isEmpty, data } = (this.$refs.signaturePad as VueSignaturePad).saveSignature();
    if (!isEmpty) {
      let pngSignature = dataURLtoFile(data, this.selectedStudentId);
      this.fileSizeInvalid = !checkFileSizeBeforeUpload(pngSignature, UploadConfiguration.SIZE_CONFIGURATION);
      if (pngSignature && this.fileSizeInvalid) return;

      await this.onPngSignatureUploadSubmit(pngSignature);

      if (this.signatureUploadSuccess) {
        this.$toasted.success(String(this.$t("messages.save_success")));
        this.studentsTheoryLesson.map((student: any) => {
          if (student.studentId === this.selectedStudentId) {
            return (student.signed = true);
          }
        });

        this.fetchTheoryLessonProvider();

        if (this.studentTheoryLessonId && fetchSignatureAfterSubmit) {
          this.fetchSignature(this.studentTheoryLessonId);
        }
        this.signatureDirty = false;
      }
    }
  }

  public onBegin(): void {
    (this.$refs.signaturePad as VueSignaturePad).resizeCanvas();
    this.signatureDirty = true;
  }
}
