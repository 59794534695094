




















import { Component, Vue, Prop } from "vue-property-decorator";
import TimePicker from "@/components/TimePicker.vue";
import type { ITime } from "@/interfaces/ITime";
import Validation from "@/components/Validation.vue";

@Component({
  components: { Validation, TimePicker },
})
export default class MultipleTime extends Vue {
  public name = "MultipleTime";

  @Prop({ type: Array, default: () => [] })
  public value!: Array<ITime>;

  @Prop()
  public validation!: any;
  @Prop({ type: Boolean, default: () => false })
  public disabled!: boolean;
}
