




















import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

@Component({
  components: {
    AbortButton,
    SaveButton,
  },
})
export default class TheorySignaturePdfOverwriteModal extends Vue {
  public name = "TheorySignaturePdfOverwriteModal";

  public onOverwriteSubmit(): void {
    this.$emit("overwrite-submit");
  }

  public onOverwriteAbort(): void {
    this.$emit("overwrite-abort");
  }
}
