import { Component, Vue } from "vue-property-decorator";
import CrudService from "@/services/CrudService";
import { AxiosError, AxiosResponse } from "axios";

const crudService = new CrudService();

@Component
export default class TheoryModuleBookMixin extends Vue {
  protected theoryModuleBookSuccess = false;
  protected theoryModuleBookError: Record<any, any> | null = null;
  protected theoryModuleBookLoading = false;

  public async theoryModuleBook(theoryId: number, date: Date): Promise<void> {
    this.theoryModuleBookLoading = true;
    this.theoryModuleBookSuccess = false;
    this.theoryModuleBookError = null;
    return await crudService
      .create({
        resource: `/theory-lesson/${theoryId}/book-module`,
        id: theoryId,
        data: date,
      })
      .then((response: AxiosResponse) => {
        this.theoryModuleBookSuccess = true;
        this.$toasted.info(this.$t("messages.operation_successful").toString());
      })
      .catch((error: AxiosError) => {
        this.theoryModuleBookError = error.response?.data;
      })
      .finally(() => {
        this.theoryModuleBookLoading = false;
      });
  }
}
